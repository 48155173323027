import ScrollReveal from 'scrollreveal';

// revealing elements on scroll 
(function() {
  ScrollReveal().reveal('.reveal', {
    distance: '30px',
    duration: 500,
    easing: 'ease-in',
    interval: 60,
    origin: 'bottom',
  });

  // with the different gradient background than what is used on siberia.io,
  // the animation doesn't flow as nicely
  /*
  ScrollReveal().reveal('.title-animated', {
    delay: 180,
    duration: 500,
    easing: 'ease-in',
    opacity: 0
  });
  */

  ScrollReveal().reveal('.header-item', {
    duration: 500,
    easing: 'ease-in',
    opacity: 0
  });

  ScrollReveal().reveal('.dots span', {
    delay: 150,
    duration: 600,
    interval: 40,
  });

  ScrollReveal().reveal('.clients .inner', {
    delay: 150,
    duration: 600,
    interval: 60,
  });
})();

// loading state
(function() {
  window.onload = function() {
    document.getElementById("loading").style.display = "none";
  };
})();

// Clients: View more button
(function() {
  const viewMoreButton = document.querySelector('.view-more a'); 
  const clientRowEls = document.querySelectorAll('.clients-row');
  const clientRowCount = clientRowEls.length - 1;
  var visibleRowCount = 0;

  document.addEventListener('click', function (event) {
    if (!event.target.matches('.view-more a')) return;
    event.preventDefault();

    ScrollReveal().sync();

    // iterate through each row of clients
    for (var i = 0; i < clientRowEls.length; i++) {
      // determine if any rows are yet to be displayed
      if (visibleRowCount <= clientRowCount) {
        let nextRow = clientRowEls[visibleRowCount];

        // display the first one that is hidden
        if (!nextRow.classList.contains('mobile-display')) {
          nextRow.classList.add('mobile-display');
          break;
        } else {
          visibleRowCount += 1;
        }
      }
    }

    // hide "View More" button if all rows are displayed
    if (visibleRowCount == clientRowCount) {
      viewMoreButton.style.display = 'none';
    }
  }, false);
})();

// Orphans: Ensure that paragraphs final lines are never a single word
(function() {
  const paragraphs = document.querySelectorAll('p');

  for (var i = 0; i < paragraphs.length; i++) {
    let text = paragraphs[i].innerHTML;
    paragraphs[i].innerHTML = text.replace(/\s([^\s<]+)\s*$/,'\u00A0$1');
  }
})();
